<template>
  <!--
    The view for the MismatchedStateReport-component.
    Used to display installations that have different states in confluence and in the MySQL-database.
  -->
  <Portlet
    :title="$t('mismatchedStateView.mismatchedStatesReport')"
    icon="thermometer-quarter"
  >
    <MismatchedStateReport />
  </Portlet>
</template>

<script>
export default {
  name: "MismatchedState",
  components: {
    MismatchedStateReport: () => import('@/components/Problem/MismatchedStateReport.vue')
  },
  metaInfo () {
    return {
      title: this.$t('mismatchedStateView.mismatchedStates')
    }
  }
}
</script>
